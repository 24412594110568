<template>
  <div :class="{ 'message-operations': true, 'incoming': incoming, 'outgoing': !incoming }">
    <v-list dense>
      <v-list-item key="edit" v-if="isEditable" @click.stop="edit">
        <v-list-item-icon><v-icon>$vuetify.icons.ic_edit</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.edit") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="pin" v-if="userCanPin && !event.isPinned" @click.stop="pin">
        <v-list-item-icon><v-icon>$vuetify.icons.ic_pin</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.pin") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="unpin" v-if="userCanPin && event.isPinned" @click.stop="unpin">
        <v-list-item-icon><v-icon>$vuetify.icons.ic_pin</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.unpin") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item key="redact" v-if="isRedactable" @click.stop="redact">
        <v-list-item-icon><v-icon color="#222222">delete_outline</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("menu.delete") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import messageMixin from "../messageMixin";
import messageOperationsMixin from "../messageOperationsMixin";

export default {
  mixins: [messageMixin, messageOperationsMixin],
  data() {
    return {}
  },
  props: {
    userCanPin: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>