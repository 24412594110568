<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble audio-bubble">
      <audio-player :event="event" :timelineSet="timelineSet">{{ $t('fallbacks.audio_file')}}</audio-player>
    </div>
  </message-outgoing>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue';
import MessageOutgoing from "./MessageOutgoing.vue";

export default {
  extends: MessageOutgoing,
  components: { MessageOutgoing, AudioPlayer },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>