<template>
  <div :class="{ 'more-less-control': true, 'collapsed': collapsed }">
    <div ref="content" class="more-less-control__content">
      <slot></slot>
    </div>
    <div class="more-less-control__read-more clickable" v-if="useExpansion && collapsed" @click="readMore">{{
      $t('global.show_more') }}</div>
    <div class="more-less-control__read-less clickable" v-else-if="useExpansion" @click="readLess">{{
      $t('global.show_less') }}</div>
  </div>
</template>

<script>
export default {
  name: "MoreLessControl",
  props: {
    event: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {
    if (this.event.isChannelMessage) {
      const el = this.$refs.content;
      if (el) {
        this.useExpansion = el.scrollHeight > el.clientHeight;
      }
    }
  },
  data() {
    return {
      collapsed: (this.event.isChannelMessage ? true : false),
      collapsedSize: 100,
      useExpansion: false,
    }
  },
  methods: {
    readMore() {
      const el = this.$refs.content;
      this.collapsedSize = el.clientHeight;
      this.collapsed = false;
      el.style.height = el.clientHeight + "px";
      el.style.maxHeight = "unset";
      requestAnimationFrame(() => {
        el.style.height = el.scrollHeight + "px";
      });
    },
    readLess() {
      const el = this.$refs.content;
      el.style.height = this.collapsedSize + "px";
      this.collapsed = true;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
