<template>
    <div v-html="content" class="waveform">
    </div>
</template>

<script>

export default {
    props: {
        event: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    computed: {
        content() {
            return this.$sanitize(
                this.event ? this.event.getContent().formatted_body : "",
                {
                    allowedTags: ["svg", "path"],
                    allowedAttributes: {
                        'svg': ["viewbox", "fill", "preserveaspectratio", "xmlns"],
                        'path': ["d", "style"]
                    }
                }
            );
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";

.waveform svg {
    width: 100%;
    height: 100%;

    path {
        stroke: black !important;
    }
}
</style>