<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble">
      <div class="original-message" v-if="inReplyToText">
        <div class="original-message-sender">{{ inReplyToSender }}</div>
        <div
          class="original-message-text"
          v-html="linkify($sanitize(inReplyToText))"
        />
      </div>

      <div class="message">
        <i v-if="event.isRedacted()" class="deleted-text">
           <v-icon size="small">block</v-icon>
          {{ redactedBySomeoneElse(event) ? $t('message.incoming_message_deleted_text') : $t('message.outgoing_message_deleted_text')}}
        </i>
        <more-less-control :event="originalEvent" v-else>
          <span v-html="linkify($sanitize(messageText))" />
          <span class="edit-marker" v-if="event.replacingEventId() && !event.isRedacted()">
            {{ $t('message.edited') }}
          </span>
        </more-less-control>
      </div>
    </div>
  </message-outgoing>
</template>

<script>
import MessageOutgoing from "./MessageOutgoing.vue";
import MoreLessControl from '../MoreLessControl.vue';

export default {
  extends: MessageOutgoing,
  components: { MessageOutgoing, MoreLessControl },
};
</script>
<style lang="scss">
@import "@/assets/css/chat.scss";
</style>