<template>
    <div ref="thumbnailRef">
        <v-responsive v-if="item.event.getContent().msgtype == 'm.video' && item.src" :class="{'thumbnail-item': true, 'preview': previewOnly}">
            <video :src="item.src"  :controls="!previewOnly" class="w-100 h-100">
                {{ $t('fallbacks.video_file') }}
            </video>
        </v-responsive>
        <v-img v-else-if="item.event.getContent().msgtype == 'm.image' && item.src" :aspect-ratio="previewOnly ? (16 / 9) : undefined" :class="{'thumbnail-item': true, 'preview': previewOnly}" :src="item.src" :contain="!previewOnly" :cover="previewOnly" />
        <div v-else :class="{'thumbnail-item': true, 'preview': previewOnly, 'file-item': true}" >
            <v-icon :class="fileTypeIconClass">{{ fileTypeIcon }}</v-icon>
            <div class="file-name">{{ $sanitize(fileName) }}</div>
            <div class="file-size">{{ fileSize }}</div>
        </div>
    </div>
</template>
<script>

import util from "../../plugins/utils";

export default {
    props: {
        /**
         * Item is an object of { event: MXEvent, src: URL }
         */
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        previewOnly: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
    },
    computed: {
        fileTypeIcon() {
            if (util.isFileTypeAPK(this.item.event)) {
                if (this.item.event.isChannelMessage) {
                    return "$vuetify.icons.ic_channel_apk";
                }
                return "$vuetify.icons.ic_apk";
            } else if (util.isFileTypeIPA(this.item.event)) {
                return "$vuetify.icons.ic_ipa";
            } else if (util.isFileTypePDF(this.item.event)) {
                if (this.item.event.isChannelMessage) {
                    return "$vuetify.icons.ic_channel_pdf";
                }
                return "$vuetify.icons.ic_pdf";
            } else if (util.isFileTypeZip(this.item.event)) {
                return "$vuetify.icons.ic_zip";
            }
            return "description"
        },
        fileTypeIconClass() {
            if (util.isFileTypeZip(this.item.event)) {
                return "zip";
            } 
            return undefined;
        },
        fileName() {
            return util.getFileName(this.item.event);
        },
        fileSize() {
            return util.getFileSizeFormatted(this.item.event);
        }
    },
    methods: {
        // listen for custom hammerJs singletab click to differentiate it from double click(heart animation).
        initThumbnailHammerJs(element) {
            const hammerInstance = util.singleOrDoubleTabRecognizer(element)

            hammerInstance.on("singletap doubletap", (ev) => {
                if(ev.type === 'singletap') {
                   this.$emit('itemclick', { item: this.item })
                }
            });
        }
    },
    mounted() {
        if(this.$refs.thumbnailRef) {
            this.initThumbnailHammerJs(this.$refs.thumbnailRef);
        }
    },
}
</script>


<style lang="scss">
@import "@/assets/css/chat.scss";

.thumbnail-item {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.file-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    flex-direction: column;
    padding: 20px;
    .v-icon {
        margin-bottom: 10px;
        color: currentColor;
    }
}
</style>