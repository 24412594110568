import { render, staticRenderFns } from "./MessageOutgoingThread.vue?vue&type=template&id=037640d0&scoped=true&"
import script from "./MessageOutgoingThread.vue?vue&type=script&lang=js&"
export * from "./MessageOutgoingThread.vue?vue&type=script&lang=js&"
import style0 from "./MessageOutgoingThread.vue?vue&type=style&index=0&id=037640d0&prod&lang=scss&"
import style1 from "./MessageOutgoingThread.vue?vue&type=style&index=1&id=037640d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037640d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VRow})
