import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

var messages = {}

function importAll(r) {
  return r.keys().map(res => {
      // Remove"./"
      const parts = res.split("/");
      const locale = parts[1].split(".")[0];
      messages[locale] = r(res);
  });
}
importAll(require.context('@/assets/translations/', true, /\.json$/));


export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: messages,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const zeroChoiseOffset = (choicesLength == 4) ? 1 : 0;

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (!teen && endsWithOne) {
        console.log("Not teen, ends with one", zeroChoiseOffset, choice);
        return zeroChoiseOffset + 0;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        console.log("Ends with 2-4", zeroChoiseOffset, choice);
        return zeroChoiseOffset + 1;
      }
      console.log("Other", choice);
      return (choicesLength < 4) ? 2 : 3;
    }
  }
})