<template>
  <div class="swipeable-thumbnails-view">
      <v-responsive :aspect-ratio="aspectRatio" class="ma-0 pa-0">
        <v-carousel height="100%" hide-delimiters :show-arrows="items.length > 1" :show-arrows-on-hover="items.length > 1" v-model="currentIndex">
          <v-carousel-item v-for="(item,index) in items" :key="item.event.getId()">
            <ThumbnailView :item="items[index]" :previewOnly="true" v-on:itemclick="itemClicked" />
          </v-carousel-item>          
        </v-carousel>
    </v-responsive>
    <div class="indicator-container" v-show="items.length > 1">
      <div v-for="(item,index) in items" :key="index" :class="{'indicator': true, 'current': index == currentIndex}" />
    </div>
  </div>
</template>

<script>
import messageMixin from "../../messages/messageMixin";
import ThumbnailView from '../../file_mode/ThumbnailView.vue';
import util from "../../../plugins/utils";

export default {
  mixins: [messageMixin],
  components: { ThumbnailView },
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    aspectRatio() {
      return this.items.some((item) =>['m.video','m.image'].includes(item.event.getContent().msgtype)) ? 16/9 : undefined;
    }
  },
  methods: {
    itemClicked() {
      if (this.items.length > 0 && this.currentIndex < this.items.length) {
        const item = this.items[this.currentIndex];
        if (util.isFileTypePDF(item.event)) {
          this.$emit("download", {event: item.event});
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>