<template>
  <div class="read-marker">
    <div class="line"></div>
    <div class="text">{{ $t('message.unread_messages') }}</div>
    <div class="line"></div>
  </div>
</template>

<script>

export default {
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>