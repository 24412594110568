<template>
  <!-- BASE CLASS FOR OUTGOING MESSAGE -->
  <div :class="messageClasses">
    <div class="senderAndTime">
      <div class="sender" v-if="room.displayType == ROOM_TYPE_CHANNEL">{{ eventSenderDisplayName(event) }}</div>
      <div class="time">
        {{ room.displayType == ROOM_TYPE_CHANNEL ? formatTimeAgo(event.event.origin_server_ts) : utils.formatTime(event.event.origin_server_ts) }}
      </div>
      <div class="status">{{ event.status }}</div>
    </div>

    <div class="op-button" ref="opbutton" v-if="!event.isRedacted() && $matrix.userCanSendMessageInCurrentRoom">
      <v-btn id="btn-show-menu" icon @click.stop="showContextMenu($refs.opbutton)">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </div>
    <div class="pin-icon" v-if="isPinned"><v-icon>$vuetify.icons.ic_pin_filled</v-icon></div>

    <!-- SLOT FOR CONTENT -->
    <span ref="messageInOutRef" class="content">
      <slot></slot>
    </span>
    <v-avatar
      class="avatar"
      size="32"
      color="#ededed"
      @click.stop="ownAvatarClicked"
    >
      <img v-if="userAvatar" :src="userAvatar" />
      <span v-else class="white--text headline">{{ userAvatarLetter }}</span>
    </v-avatar>
    <QuickReactionsChannel v-if="room.displayType == ROOM_TYPE_CHANNEL" :event="eventForReactions" :timelineSet="timelineSet" v-on="$listeners"/>
    <QuickReactions v-else :event="eventForReactions" :timelineSet="timelineSet" v-on="$listeners"/>
    <SeenBy v-if="room.displayType != ROOM_TYPE_CHANNEL" :room="room" :event="event"/>
  </div>
</template>

<script>
import SeenBy from "./SeenBy.vue";
import messageMixin from "./messageMixin";
import util, { ROOM_TYPE_CHANNEL } from "../../plugins/utils";
import QuickReactions from "./QuickReactions.vue";
import QuickReactionsChannel from "./channel/QuickReactionsChannel.vue";

export default {
  mixins: [messageMixin],
  components: { QuickReactions, QuickReactionsChannel, SeenBy },
    data() {
      return { ROOM_TYPE_CHANNEL: ROOM_TYPE_CHANNEL }
    },
  mounted() {
    if(util.isMobileOrTabletBrowser() && this.$refs.messageInOutRef) {
      this.initMsgHammerJs(this.$refs.messageInOutRef);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/css/chat.scss";
</style>