import { render, staticRenderFns } from "./SwipeableThumbnailsView.vue?vue&type=template&id=174a4d63&"
import script from "./SwipeableThumbnailsView.vue?vue&type=script&lang=js&"
export * from "./SwipeableThumbnailsView.vue?vue&type=script&lang=js&"
import style0 from "./SwipeableThumbnailsView.vue?vue&type=style&index=0&id=174a4d63&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VCarousel,VCarouselItem,VResponsive})
